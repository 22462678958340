import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Giusto Newport",
    pageDescription:
      "How Giusto Newport reduced the need for time-intensive spreadsheets and increased efficiency.",
    title: "Fewer spreadsheets. More teamwork",
    subTitle: `Unique dining spot Giusto Newport cultivates a positive work environment with a little help from SpotOn Teamwork.`,
    businessName: "Giusto Newport",
    industry: "Restaurant, casual dining",
    location: "Newport, Rhode Island",
    products: [spotonProducts.LABOR_MANAGEMENT],
    description:
      "Giusto Newport is more than just an outstanding place to eat and drink. It’s also an awesome place to work. General manager Lauren Schaefer uses her years of restaurant management experience to create a work environment where her team can learn and grow. To make it happen, Schaefer uses time-saving SpotOn Teamwork tools to handle tip calculation, scheduling, and labor forecasting so she can ditch the finicky spreadsheets and focus her energy on coaching her team.",
    quoteData: {
      imgName: "guisto-newport.png",
      quote: `“By saving time with SpotOn Teamwork, there’s more time to spend together as a team on more creative endeavors. We can come up with other ways to optimize the business and make our operations more efficient when we’re not stuck in the weeds with payroll and scheduling.”`,
      personName: "Lauren Schaefer",
      personTitle: "General Manager, Giusto Newport",
    },
    goal: `Giusto Newport needed a labor management tool that could be customized to fit their complicated tip formula and integrate smoothly with their existing tech. They also wanted to increase operational efficiency around scheduling and payroll.`,
    solution: `SpotOn Teamwork transformed Giusto’s analog processes for scheduling and tip calculation, reducing the time Schaefer spends on these tasks to just two hours a week. Labor forecasting helps Schaefer keep costs in check during the quiet season and ensure Giusto is adequately staffed when things ramp up. SpotOn Teamwork also calculates tips based on Giusto’s custom formula in minutes—so Schaefer’s team gets paid accurately and on time, no matter the tip system complexity.`,
    results: {
      title: `The results`,
      stats: [
        { title: `5 - 7 hours`, description: `saved on weekly operations` },
        {
          title: `5 - 10 minutes`,
          description: `faster order times`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
      title: "Creating better work experiences with SpotOn Teamwork",
      imageName: "the-bungalow-kitchen.png",
      industry: resourceIndustries.RESTAURANTS,
      tag: resourceTags.CASE_STUDY,
      date: "2022-08-18",
      href: "/case-studies/the-bungalow-kitchen",
      goals: [
        resourceGoals.STREAMLINE_OPERATIONS,
        resourceGoals.GET_SMARTER_DATA,
        resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
      ],
    },
    {
      title: "Hot & ready without the hassle",
      imageName: "callies-hot-little-biscuit.png",
      industry: resourceIndustries.RESTAURANTS,
      tag: resourceTags.CASE_STUDY,
      date: "2022-10-05",
      href: "/case-studies/callies-hot-little-biscuit",
      goals: [
        resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        resourceGoals.STREAMLINE_OPERATIONS,
        resourceGoals.GET_SMARTER_DATA,
      ],
    },
    {
      title: "Managing 3 popular Japanese restaurants",
      imageName: "momoya-with-logo.png",
      industry: resourceIndustries.RESTAURANTS,
      tag: resourceTags.CASE_STUDY,
      date: "2022-07-22",
      href: "/case-studies/momoya",
      goals: [
        resourceGoals.STREAMLINE_OPERATIONS,
        resourceGoals.GET_SMARTER_DATA,
        resourceGoals.CONNECT_AND_SELL_ONLINE,
      ],
    },
  ];
  